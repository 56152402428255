import React from "react"
import { Link } from "gatsby"
import { Container } from "react-bootstrap"
import Img from "gatsby-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"

const SeancesPage = ({ data }) => (
  <Layout>
    <Seo title="Page two" />

    <div style={{ maxHeight: `500px`, position: "relative" }}>
      <Img
        fixed={data.file.childImageSharp.fixed}
        style={{ maxHeight: 500, width: "100%", objectFit: "cover" }}
      />
    </div>

    <Container>
      <h1>Hi from the séance page</h1>
      <p>Welcome to Profil</p>
      <Link to="/">Go back to the homepage</Link>
    </Container>
  </Layout>
)

export default SeancesPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    file(relativePath: { eq: "mains_ostheopate.jpg" }) {
      childImageSharp {
        fixed(width: 1920, height: 1280) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
